import React from 'react'
import ProfilePicture from '../../components/profilePicture/ProfilePicture'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import style from './Home.module.css'
import { FiDownload } from 'react-icons/fi'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Title from '../../components/title/Title'

const useStyles = makeStyles({
  outlinedBtn: {
    fontSize: 10,
    backgroundColor: '#808dad15'
  },
  textBtn: {
    fontSize: 10,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#fff !important'
    },
    verticalAlign: 'top'
  }
})

function Home () {
  const classes = useStyles()
  return (
    <div className={style.container}>
      <Grid
        container
        spacing={2}
        direction='row'
        alignItems='center'
        justify='center'
        style={{ height: '60vh' }}
      >
        <Grid item xs={6} style={{ paddingLeft: '7vw' }}>

          <Title text='Hello' />
          <h1 className={style.h1}>I'm Niklas<br />Andersson</h1>
          <h2 className={style.subTitle}>Fullstack Developer</h2>

          <div className={style.buttonContainer}>
            <Link to='/contact'>
              <Button
                className={classes.outlinedBtn}
                variant='outlined'
                color='primary'
              >Hire me
              </Button>
            </Link>
            <Button
              className={classes.textBtn}
              size='small'
              color='primary'
              endIcon={<FiDownload />}
              href='/files/CV-Niklas-Andersson.pdf'
              target='_blank'
            >Download CV
            </Button>
          </div>

        </Grid>

        <Grid item xs={5} style={{ textAlign: 'left' }}>
          <ProfilePicture />
        </Grid>
      </Grid>
    </div>
  )
}

export default Home
