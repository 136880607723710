import React from 'react'
import style from './Title.module.css'
import { useSpring, animated } from 'react-spring'

function Title ({ text }) {
  const props = useSpring({
    to: { opacity: 1, marginLeft: 0 },
    from: { opacity: 0, marginLeft: -50 },
    config: { duration: 500, delay: 1000 }
  })
  return (
    <animated.div style={props}>
      <h2 className={style.title}>&mdash; {text}</h2>
    </animated.div>

  )
}

export default Title
