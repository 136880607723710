import React from 'react'
import style from './AboutPictures.module.css'
import bulb from './bulb.jpg'
import hands from './hands.jpg'
import smoke from './smoke.jpg'

function AboutPictures () {
  return (
    <div className={style.container}>
      <ul>
        <li><img src={bulb} alt='bulb' /></li>
        <li><img src={smoke} alt='smoke' /></li>
        <li> <img src={hands} alt='hands' /></li>
      </ul>
    </div>
  )
}

export default AboutPictures
