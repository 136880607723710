import Home from '../../views/home/Home.js'
import About from '../../views/about/About.js'
import Skills from '../../views/skills/Skills.js'
import Portfolio from '../../views/portfolio/Portfolio.js'
import Contact from '../../views/contact/Contact.js'

var routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-me',
    name: 'About',
    component: About
  },
  {
    path: '/skills',
    name: 'Skills',
    component: Skills
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }
]

export default routes
