import React from 'react'
import { Switch } from 'react-router-dom'
import './App.css'
import Nav from './components/nav/Nav'
import Content from './components/content/Content'
import generateRoutes from './utils/routes/generateRoutes'
import routes from './utils/routes/routes'
import Social from './components/social/Social'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme/Theme'

function App () {
  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <header className='App-header'>
          <div className='outerContainer'>
            <div className='innerContainer'>
              <Nav />
              <Content className='content'>
                <Switch>
                  {generateRoutes(routes)}
                </Switch>
              </Content>
              <Social className='social' />
            </div>
          </div>
        </header>
      </div>
    </ThemeProvider>
  )
}

export default App
