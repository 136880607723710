import React from 'react'
import style from './Nav.module.css'
import { FaPhoneAlt } from 'react-icons/fa'
import Logo from '../logo/Logo'
import { NavLink, useLocation } from 'react-router-dom'
import routes from '../../utils/routes/routes'

function Nav () {
  const location = useLocation()
  const activeRoute = (routeName) => {
    return location.pathname === routeName
      ? 'active'
      : ''
  }
  return (
    <nav className={style.nav}>
      <div className={style.logoContainer}>
        <Logo />
      </div>
      <div className={style.ulContainer}>
        <ul>
          {routes.map((prop, key) => {
            return (
              <li
                className={
                  activeRoute(prop.path) + (prop.pro ? ' active-pro' : '')
                }
                key={key}
              >
                <NavLink
                  to={prop.path}
                  className='nav-link'
                  activeClassName='active'
                >
                  {prop.name}
                </NavLink>
              </li>
            )
          })}
          <li>|</li>
          <li><FaPhoneAlt /><a href='tel:+46728869821' className={style.phone}> +46 72 886 98 21</a></li>
        </ul>
      </div>

    </nav>
  )
}

export default Nav
