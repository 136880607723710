import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#EB4A4A'
    },
    secondary: {
      main: '#808DAD'
    }
  }
})

export default theme
