import React from 'react'
import style from './Logo.module.css'
import logoSVG from './logo.png'

function Logo () {
  return (
    <>
      <a href='/'><img className={style.logo} src={logoSVG} alt='Logo' /></a>
    </>
  )
}

export default Logo
