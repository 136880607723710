import React from 'react'
import profilePicture from './profilePicture.jpg'
import style from './ProfilePicture.module.css'

function ProfilePicture () {
  return (
    <>
      <img className={style.img} src={profilePicture} alt='profile' />
    </>
  )
}

export default ProfilePicture
