import iloAppImg from './IloApp.jpg'
import hakimAppImg from './HakimApp.jpg'
import portfolioAppImg from './portfolioApp.jpg'
import imagePlaceholder from './imagePlaceholder.jpg'
import bangkokGreenMarketImg from './bangkokGreenMarket.jpg'

const projects = [
  {
    id: 'pr1',
    title: 'Smart Signup Form',
    description: 'Signup form connected to Google Sheet for ILO,UN.',
    tags: ['Fullstack', 'ReactJs', 'Google API'],
    image: iloAppImg,
    live: 'https://rebuildingbetter.netlify.app/',
    gitlab: 'https://gitlab.com/ilosignupform'
  },
  {
    id: 'pr2',
    title: 'Workout Booking App',
    description: 'A booking app made for coach Hakim Azahar.',
    tags: ['Fullstack', 'ReactJs', 'Node.js'],
    image: hakimAppImg,
    live: 'https://hakimazahar.netlify.app',
    gitlab: 'https://gitlab.com/bookworkoutclassapp',
    dribbble: 'https://dribbble.com/shots/16107050-Workout-Booking-Web-App'
  },
  {
    id: 'pr3',
    title: 'Portfolio Website',
    description: 'Portfolio website to present my work.',
    tags: ['Frontend', 'ReactJs', 'Material UI'],
    image: portfolioAppImg,
    live: 'https://www.niklasaandersson.com',
    gitlab: 'https://gitlab.com/portfoliowebsite',
    dribbble: 'https://dribbble.com/shots/16107061-Portfolio-Web-App'
  },
  {
    id: 'pr4',
    title: 'E-commerce Shop',
    description: 'E-commerce website for Bangkok Green Market.',
    tags: ['Frontend', 'Wordpress', 'PHP'],
    image: bangkokGreenMarketImg
  }
]

export default projects
