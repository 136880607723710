import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { FiGitlab, FiDribbble, FiEye } from 'react-icons/fi'
import { FaBehance } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  subheader: {
    color: '#EB4A4A'
  },
  root: {
    minWidth: '190px',
    width: '190px',
    height: '310px',
    display: 'inline-block',
    backgroundColor: '#808dad15',
    margin: '0.5em'
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  icon: {
    fontSize: '0.5em',
    '&:hover': {
      transform: 'scale(1.2)'
    }
  },
  tag: {
    fontSize: '9px',
    display: 'inline-block',
    marginRight: '4px',
    backgroundColor: 'rgb(38, 44, 70)',
    color: '#808dad',
    padding: '2px 4px',
    borderRadius: '2px',
    fontWeight: 700,
    textAlign: 'left',
    marginBlockStart: '-1em',
    marginBlockEnd: 0
  }

}))

export default function RecipeReviewCard (props) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{
          subheader: classes.subheader
        }}
        subheader={props.title}
        style={{ paddingBottom: 0, minHeight: '48px' }}
      />
      <CardContent style={{ paddingTop: '0.2em', paddingBottom: '0.3em' }}>
        <Typography
          variant='body2'
          style={{ color: '#808DAD', fontSize: '10px' }}
          component='p'
        >
          {props.description}
        </Typography>
      </CardContent>
      <CardContent style={{ textAlign: 'left', paddingTop: 0, paddingBottom: '0.5em', marginTop: '-0.5em', height: '50px' }}>
        {props.tags.map(tag => (
          <p key={tag} className={classes.tag}>{tag}</p>
        ))}
      </CardContent>
      <CardMedia
        className={classes.media}
        image={props.image}
        title='App img'
      />
      <CardActions disableSpacing>

        {props.live
          ? <IconButton aria-label='Live' className={classes.icon}>
            <Link to={{ pathname: props.live }} target='_blank'><FiEye color='#808DAD' /></Link>
            </IconButton>
          : null}

        {props.gitlab
          ? <IconButton aria-label='GitLab' className={classes.icon}>
            <Link to={{ pathname: props.gitlab }} target='_blank'><FiGitlab color='#808DAD' /></Link>
            </IconButton>
          : null}

        {props.behance
          ? <IconButton aria-label='Behance' className={classes.icon}>
            <Link to={{ pathname: props.behance }} target='_blank'><FaBehance color='#808DAD' /></Link>
          </IconButton>
          : null}

        {props.dribbble
          ? <IconButton aria-label='Dribbble' className={classes.icon}>
            <Link to={{ pathname: props.dribbble }} target='_blank'><FiDribbble color='#808DAD' /></Link>
            </IconButton>
          : null}
      </CardActions>
    </Card>
  )
}
