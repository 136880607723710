import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
})

export default function LinearDeterminate (props) {
  const classes = useStyles()
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10
        return Math.min(oldProgress + diff, props.value)
      })
    }, 150)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <div className={classes.root}>
      <LinearProgress variant='determinate' value={progress} style={{ backgroundColor: '#808DAD' }} />
    </div>
  )
}
