import React from 'react'
import style from './EnglishBar.module.css'

function EnglishBar () {
  return (
    <>
      <div className={style.circular}>
        <div className={style.inner} />
        <div className={style.label}><p>English</p><p className={style.level}>Fluent</p></div>

        <div className={style.circle}>
          <div className={style.bar + ' ' + style.left}>
            <div className={style.progress} />
          </div>
          <div className={style.bar + ' ' + style.right}>
            <div className={style.progress} />
          </div>

        </div>
      </div>

    </>
  )
}

export default EnglishBar
