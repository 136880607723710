import React from 'react'
import style from './SwedishBar.module.css'

function SwedishBar () {
  return (
    <>
      <div className={style.circular}>
        <div className={style.inner} />
        <div className={style.label}><p>Swedish</p><p className={style.level}>Mother tongue</p></div>

        <div className={style.circle}>
          <div className={style.bar + ' ' + style.left}>
            <div className={style.progress} />
          </div>
          <div className={style.bar + ' ' + style.right}>
            <div className={style.progress} />
          </div>

        </div>
      </div>

    </>
  )
}

export default SwedishBar
