import React from 'react'
import style from './Portfolio.module.css'
import projects from '../../data/projects'
import ProjectCard from '../../components/ProjectCard/ProjectCard'
import Title from '../../components/title/Title'

function Portfolio () {
  return (
    <div className={style.container}>
      <Title text='Projects' />
      <div className={style.projectsContainer}>
        {projects.map(project => (
          <ProjectCard key={project.id} title={project.title} description={project.description} tags={project.tags} image={project.image} live={project.live} gitlab={project.gitlab} dribbble={project.dribbble} behance={project.behance} />
        ))}
      </div>
    </div>
  )
}

export default Portfolio
