import React from 'react'
import { FiMessageSquare, FiFacebook, FiInstagram, FiLinkedin, FiGitlab, FiDribbble } from 'react-icons/fi'
import { FaBehance } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import style from './Social.module.css'

function Social () {
  return (
    <div className={style.container}>
      <ul className={style.ul}>

        <li><Link to={{ pathname: 'https://www.facebook.com/nikand11/' }} target='_blank'><FiFacebook color='#808DAD' /></Link></li>
        <li><Link to={{ pathname: 'https://www.instagram.com/niklasaa/' }} target='_blank'><FiInstagram color='#808DAD' /></Link></li>
        <li><Link to={{ pathname: 'https://www.linkedin.com/in/niklas-a-andersson/' }} target='_blank'><FiLinkedin color='#808DAD' /></Link></li>
        <li><Link to={{ pathname: 'https://www.behance.net/niklasandersson5' }} target='_blank'><FaBehance color='#808DAD' /></Link></li>
        <li><Link to={{ pathname: 'https://dribbble.com/niklasaandersson' }} target='_blank'><FiDribbble color='#808DAD' /></Link></li>
        <li><Link to={{ pathname: 'https://gitlab.com/users/niklasaandersson/groups' }} target='_blank'><FiGitlab color='#EB4A4A' /></Link></li>
        <li><span>-</span></li>
        <li><span>Follow Me</span></li>
        <li />
        <li> <a href='sms:+46728869821&body=Hi Niklas'><FiMessageSquare color='#808DAD' /></a></li>
      </ul>
    </div>
  )
}

export default Social
