import React from 'react'
import style from './Skills.module.css'
import ProgressBar from '../../components/bars/progressBar/ProgressBar'
import SwedishBar from '../../components/bars/swedish/SwedishBar'
import EnglishBar from '../../components/bars/english/EnglishBar'
import { GiTennisBall, GiPineTree, GiCookingPot, GiBodyBalance, GiSittingDog } from 'react-icons/gi'
import Title from '../../components/title/Title'

function Skills () {
  return (
    <div className={style.container}>
      <div className={style.flexItemLeft}>
        <div className={style.barContainer}>
          <Title text='Tech Skills' />
          <ul className={style.skillsList}>
            <li>Adobe Photoshop {<ProgressBar value={70} />}</li>
            <li>Adobe Illustrator {<ProgressBar value={70} />}</li>
            <li>C# {<ProgressBar value={40} />}</li>
            <li>CSS3 {<ProgressBar value={80} />}</li>
            <li>Excel {<ProgressBar value={100} />}</li>
            <li>HTML5 {<ProgressBar value={80} />}</li>
            <li>Git {<ProgressBar value={70} />}</li>
            <li>Java{<ProgressBar value={40} />}</li>
            <li>JavaScript {<ProgressBar value={80} />}</li>
            <li>Node.js {<ProgressBar value={70} />}</li>
            <li>PHP {<ProgressBar value={40} />}</li>
            <li>ReactJS {<ProgressBar value={60} />}</li>
            <li>Sketch {<ProgressBar value={60} />}</li>
            <li>Wordpress {<ProgressBar value={70} />}</li>
          </ul>
        </div>
      </div>
      <div className={style.flexItemRight}>
        <div className={style.flexItemRightUpper}>
          <Title text='Other Skills' />
          <ul>
            <li><GiBodyBalance color='808DAD' /><p>Calisthenics<br />&amp; Handstand</p></li>
            <li><GiTennisBall color='#808DAD' /><p>Tennis</p></li>
            <li><GiPineTree color='#808DAD' /><p>Nature</p></li>
            <li><GiSittingDog color='#808DAD' /><p>My Dog</p></li>
            <li><GiCookingPot color='#808DAD' /><p>Cooking</p></li>
          </ul>
        </div>
        <div className={style.flexItemRightLower}>
          <Title text='Language Skills' />
          <SwedishBar />
          <EnglishBar />
        </div>
      </div>

    </div>
  )
}

export default Skills
