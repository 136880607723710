import React, { useState } from 'react'
import style from './Contact.module.css'
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from 'react-icons/fa'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Title from '../../components/title/Title'
import * as emailjs from 'emailjs-com'

const SERVICE_ID = 'service_elfuqxs'
const TEMPLATE_ID = 'template_6689a4g'
const USER_ID = 'user_ebygYRv8W0NA1hm1PmKv0'

const useStyles = makeStyles({
  outlinedBtn: {
    fontSize: 10,
    backgroundColor: '#808dad15',
    float: 'left',
    disabled: {
      color: 'green'
    }
  },
  root: {
    backgroundColor: '#808DAD40',
    borderRadius: '4px',
    marginBottom: '0.5em'
  },
  input: {
    fontSize: '0.5em',
    color: '#fff'
  },
  cssLabel: {
    color: '#808dad !important',
    fontSize: '0.5em'
  }
})

function Contact () {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [mailSuccess, setMailSuccess] = useState('')
  const [mailError, setMailError] = useState('')
  const [fromName, setFromName] = useState('')
  const [fromEmail, setFromEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const onSubmit = (e) => {
    setIsLoading(true)
    e.preventDefault()
    emailjs.send(SERVICE_ID, TEMPLATE_ID,
      {
        fromName: fromName,
        fromEmail: fromEmail,
        subject: subject,
        message: message
      }
      , USER_ID).then(
      function (response) {
        if (response.status === 200) {
          setIsLoading(false)
          setMailSuccess(true)
          setFromName('')
          setFromEmail('')
          setSubject('')
          setMessage('')
          setTimeout(() => setMailSuccess(''), 3000)
        } else {
          setIsLoading(false)
          setMailError(true)
          setTimeout(() => setMailError(''), 3000)
        }
      },
      function (err) {
        console.log(err)
      }
    )
  }

  return (
    <div className={style.container}>
      <div className={style.flexItemLeft}>
        <Title text='Contact Me' />
        <h3 className={style.subTitle}>Interested in my work?</h3>
        <p className={style.p}>
          Don't you love hiring carpenters that both are the boss and the actual one who performs the work? I do, and that is what you get when you hire me!
        </p>
        <ul className={style.ul}>
          <li><FaMapMarkerAlt color='#EB4A4A' style /><a href='#' className={style.p}>Bangkok, Thailand &amp; Stockholm, Sweden</a></li>
          <li><FaEnvelope color='#EB4A4A' /><a href='mailto:niklas.andersson.1988@gmail.com' className={style.p}>niklas.andersson.1988@gmail.com</a></li>
          <li><FaPhoneAlt color='#EB4A4A' /><a href='tel:+46728869821' className={style.p}>+46 72 886 98 21</a></li>
        </ul>
      </div>
      <div className={style.flexItemRight}>
        <form onSubmit={onSubmit} autoComplete='off'>
          <TextField
            id='filled-basic'
            label='Your Name'
            variant='filled'
            size='small'
            type='text'
            required
            fullWidth
            className={classes.root}
            InputProps={{
              className: classes.input
            }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused
              }
            }}
            name='fromName'
            value={fromName}
            onChange={(e) => setFromName(e.target.value)}
          />
          <TextField
            id='filled-basic'
            label='Email Address'
            variant='filled'
            size='small'
            type='email'
            required
            fullWidth
            className={classes.root}
            InputProps={{
              className: classes.input
            }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused
              }
            }}
            name='fromEmail'
            value={fromEmail}
            onChange={(e) => setFromEmail(e.target.value)}
          />
          <TextField
            id='filled-basic'
            label='Subject'
            variant='filled'
            size='small'
            type='text'
            required
            fullWidth
            className={classes.root}
            InputProps={{
              className: classes.input
            }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused
              }
            }}
            name='subject'
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <TextField
            id='filled-basic'
            label='Message'
            variant='filled'
            size='small'
            type='text'
            required
            fullWidth
            className={classes.root}
            InputProps={{
              className: classes.input
            }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused
              }
            }}
            multiline
            rowsMax={4}
            name='message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button
            classes={{ root: classes.outlinedBtn }}
            variant='outlined'
            color='primary'
            type='submit'
          >
            {isLoading ? 'Loading' : 'Send'}
          </Button>
          {mailSuccess ? <p className={style.mailStatus + ' ' + style.mailSuccess}>Message sent!</p> : null}
          {mailError ? <p className={style.mailStatus + ' ' + style.mailSuccess}>Something went wrong!</p> : null}
        </form>
      </div>
    </div>
  )
}

export default Contact
