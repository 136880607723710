import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import style from './About.module.css'
import { FiDownload } from 'react-icons/fi'
import AboutPictures from '../../components/aboutPictures/AboutPictures'
import { Link } from 'react-router-dom'
import Title from '../../components/title/Title'

const useStyles = makeStyles({
  outlinedBtn: {
    fontSize: 10,
    backgroundColor: '#808dad15'
  },
  textBtn: {
    fontSize: 10,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#fff !important'
    }
  }
})

function About () {
  const classes = useStyles()
  return (
    <div className={style.container}>
      <div className={style.flexItemLeft}>
        <Title text='About Me' />
        <h3 className={style.subTitle}>I'm here to help you with your next project </h3>
        <p className={style.p}>My name is Niklas Andersson and I am a Bangkok-Stockholm-based software developer with previous work experience in supply chain, logistics and project management. I have an educational background in both software development and business management.<br /><br />
In recent years, I embarked on an entrepreneurial journey and built an online business platform connecting organic farmers with end-customers and enabling a harvest-to-order concept. I learnt a lot and it led me to my new passion, web development. After almost three years, I decided to close down the platform and I am now looking for opportunities with a software company where I can utilise my previous experiences and various skill set.
        </p>
        <div className={style.buttonContainer}>
          <Link to='/portfolio'>
            <Button
              className={classes.outlinedBtn}
              variant='outlined'
              color='primary'
            >My Portfolio
            </Button>
          </Link>
          <Button
            className={classes.textBtn}
            size='small'
            color='primary'
            endIcon={<FiDownload />}
            href='/files/CV-Niklas-Andersson.pdf'
            target='_blank'
          >Download CV
          </Button>
        </div>
      </div>
      <div className={style.flexItemRight}>
        <AboutPictures />
      </div>
    </div>
  )
}

export default About
